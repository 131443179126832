.brand-section {
  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: var(--gray-4);
    text-transform: uppercase;
  }

  .rfm-child {
    margin-inline-end: 116px;

    @media #{$lg} {
      margin-inline-end: 60px;
    }
    @media #{$sm} {
      margin-inline-end: 40px;
    }
  }

  .shape {
    position: absolute;
    bottom: -12px;
    inset-inline-start: calc(33.33% - 11px);
    z-index: 1;

    &-2 {
      position: absolute;
      bottom: -12px;
      inset-inline-start: calc(66.66% - 11px);
      z-index: 1;
    }
  }

  .slider-width {
    max-width: 930px;
    margin: 0 auto;
  }
}

.brand-section-2 {
  .sub-title-service {
    font-size: 60px;
    font-weight: 400;

    @media #{$lg} {
      font-size: 45px;
    }

    @media #{$md} {
      font-size: 36px;
    }
  }
  .brand {
    .item-wrapper {
      display: grid;
      grid-template-columns: repeat(6, 1fr);

      @media #{$sm} {
        grid-template-columns: repeat(3, 1fr);
      }

      @media #{$xs} {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--white);
      min-width: 100px;
      aspect-ratio: 100/100;
      padding: 0 20px;
      margin-top: -1px;
      border-inline-start-color: transparent;

      @include light {
        border-color: var(--black-2);
        border-inline-start-color: transparent;
      }

      @media #{$sm} {
        &.border-0 {
          display: none;
        }

        border: none;
        aspect-ratio: 100/50;
      }

      &:nth-child(1) {
        grid-column-start: 2;
        border-inline-start-color: var(--white);

        @include light {
          border-color: var(--black-2);
          border-inline-start-color: var(--black-2);
        }

        @media #{$sm} {
          grid-column-start: auto;
        }
      }

      &:nth-child(2) {
        grid-column-start: 1;
        border-inline-start-color: var(--white);

        @include light {
          border-color: var(--black-2);
          border-inline-start-color: var(--black-2);
        }

        @media #{$sm} {
          grid-column-start: auto;
        }
      }

      &:nth-child(7) {
        grid-column-start: 2;
        border-inline-start-color: var(--white);

        @include light {
          border-color: var(--black-2);
          border-inline-start-color: var(--black-2);
        }

        @media #{$sm} {
          grid-column-start: auto;
        }
      }

      &:nth-child(12) {
        grid-column-start: 5;
        border-inline-start-color: var(--white);

        @include light {
          border-color: var(--black-2);
          border-inline-start-color: var(--black-2);
        }

        @media #{$sm} {
          grid-column-start: auto;
        }
      }
    }

    .area-text {
      p {
        font-size: 20px;
        color: var(--white);
        font-family: $font_glorifydemo;
        line-height: 1.25;
        text-transform: uppercase;
        max-width: 300px;
        margin: 0 auto;
        padding-inline-start: 20px;
        transform: translateY(100%);
        margin-bottom: -70px;
        padding-top: 20px;

        @include light {
          color: #1c1d20;
        }

        @media #{$xl} {
          margin-bottom: -60px;
          padding-top: 10px;
        }

        @media #{$lg} {
          margin-bottom: -50px;
          padding-top: 0px;
          padding-inline-start: 30px;
        }

        @media #{$md} {
          padding-inline-start: 60px;
          transform: translateY(70%);
        }

        @media #{$sm} {
          transform: none;
          margin-bottom: 30px;
          padding-inline-start: 0;
          text-align: center;
        }
      }
    }
  }

  .area-text {
    p {
      font-size: 20px;
      color: var(--white);
      font-family: $font_glorifydemo;
      line-height: 1.25;
      text-transform: uppercase;
      max-width: 300px;
      margin: 0 auto;
      padding-inline-start: 20px;
      transform: translateY(100%);
      margin-bottom: -70px;
      padding-top: 20px;

      @include light {
        color: var(--black-2);
      }

      @media #{$xl} {
        margin-bottom: -60px;
        padding-top: 10px;
      }

      @media #{$lg} {
        margin-bottom: -50px;
        padding-top: 0px;
        padding-inline-start: 30px;
      }

      @media #{$md} {
        padding-inline-start: 60px;
        transform: translateY(70%);
      }

      @media #{$sm} {
        transform: none;
        margin-bottom: 30px;
        padding-inline-start: 0;
        text-align: center;
      }
    }
  }
}
