// Social Media Hover Animation
.hover {
  &-zoom {
    li a:hover {
      transform: scale(1.1);
    }
  }

  &-rotate {
    li a {
      i {
        transition: all 1s;
      }

      &:hover i {
        transform: rotate(360deg);
      }
    }
  }

  &-space li a {
    &:hover {
      letter-spacing: 1px;
    }
  }

  &-border-move {
    li a {
      position: relative;
      transition: all 0.5s;

      &:hover {
        &::after {
          width: 100%;
          left: auto;
          right: 0;
        }
      }

      &::after {
        position: absolute;
        content: "";
        width: 0%;
        height: 1px;
        bottom: 0;
        left: 0;
        transition: all 0.5s;
        background-color: currentColor;
      }
    }
  }

}

// 
// Menu Hover Animation
//
.menu-hover {
  &-default li a {
    &:hover {
      color: var(--primary);
    }
  }

  &-space li a {
    &:hover {
      letter-spacing: 1px;
    }
  }



}



// 
// Blog Hover Animatoin
// 
.blog-img {
  &-leftmove {
    img {
      transform: scale(1.1);
      transition: all 0.5s;
    }

    &:hover {
      img {
        transform: scale(1.1) translateX(4%);
      }
    }
  }

  &-zoom {
    img {
      transform: scale(1);
      transition: all 1s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }



}




// Title Hover Animation
.title-hover-flip {
  overflow: hidden;
  perspective: 1000px;

  strong {
    font-weight: 400;
    position: relative;
    display: inline-block;
    transition: transform 0.5s;
    transform-origin: 50% 0;
    transform-style: preserve-3d;

    &:before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      content: attr(data-hover);
      transition: all 0.5s;
      transform: rotateX(-90deg);
      transform-origin: 50% 0;
    }
  }

  &:hover strong {
    transform: rotateX(90deg) translateY(-22px);
  }
}



// Service Animatoin
.service {
  &-hover {
    &-default {
      &:hover {
        background-color: var(--white-6);
      }
    }
  }


  &-rollover {
    &-right {
      position: relative;
      transition: all 0.5s;

      &::after {
        position: absolute;
        content: "";
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--white-6);
        transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
      }

      &:hover::after {
        width: 100%;
        left: auto;
        right: 0%;
      }
    }

    &-left {
      position: relative;
      transition: all 0.5s;

      &::after {
        position: absolute;
        content: "";
        width: 0%;
        height: 100%;
        top: 0;
        right: 0;
        background-color: var(--white-6);
        transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
      }

      &:hover::after {
        width: 100%;
        right: auto;
        left: 0%;
      }
    }

    &-top {
      position: relative;
      transition: all 0.5s;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 0%;
        left: 0;
        bottom: 0;
        background-color: var(--white-6);
        transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
      }

      &:hover::after {
        height: 100%;
        bottom: auto;
        top: 0%;
      }
    }

    &-bottom {
      position: relative;
      transition: all 0.5s;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 0%;
        top: 0;
        left: 0;
        background-color: var(--white-6);
        transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
      }

      &:hover::after {
        height: 100%;
        top: auto;
        bottom: 0%;
      }
    }
  }
}


// 
// Instagram
// 