.blog-section {
  .sec-title-18 {
    margin-top: 80px;
    margin-bottom: 70px;
    @media #{$sm} {
      margin-top: 50px;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px 60px;

    @media #{$md} {
      grid-template-columns: repeat(2, 1fr);
      gap: 50px 40px;
    }

    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }

  .pagination {
    gap: 20px;
    .prev {
      color: #a7a7a7;

      &:hover {
        color: var(--white);

        @include light {
          color: #202c58;
        }
      }

      svg {
        margin-inline-end: 8px;

        @include rtl {
          transform: rotateY(180deg);
        }
      }
    }

    .next {
      color: #a7a7a7;

      &:hover {
        color: var(--white);

        @include light {
          color: #202c58;
        }
      }

      svg {
        margin-inline-start: 8px;

        @include rtl {
          transform: rotateY(180deg);
        }
      }
    }

    .number {
      display: flex;
      gap: 20px;

      li {
        a {
          &.active {
            color: var(--white);

            @include light {
              color: #202c58;
            }
          }

          color: #a7a7a7;

          &:hover {
            color: var(--white);

            @include light {
              color: #202c58;
            }
          }

          svg {
            padding-inline-start: 8px;

            @include rtl {
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
  }
}

.blog-card {
  overflow: hidden;
  margin-top: 0px;
  display: inline-block;

  &:nth-child(2n) {
    .thumb {
      aspect-ratio: 100/66;
    }
  }

  &:nth-child(6n + 1),
  &:nth-child(6n + 2),
  &:nth-child(6n + 3) {
    align-self: flex-start;
  }

  &:nth-child(6n + 4),
  &:nth-child(6n + 5),
  &:nth-child(6n + 6) {
    align-self: flex-end;
    margin-top: calc(-37% - 80px);

    @media #{$md} {
      margin-top: 0;
    }
  }

  @media #{$lg} {
    margin-top: 40px;
  }

  @media #{$md} {
    &:nth-child(4n + 2),
    &:nth-child(4n + 3) {
      .thumb {
        aspect-ratio: 100/66;
      }
    }

    &:nth-child(4n) {
      margin-top: calc(-43% - 50px);

      .thumb {
        aspect-ratio: 100/124;
      }
    }

    &:nth-child(4n + 1),
    &:nth-child(4n + 2) {
      align-self: flex-start;
    }

    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      align-self: flex-end;
    }
  }

  @media #{$sm} {
    margin-top: 0 !important;

    .thumb {
      aspect-ratio: 100/66 !important;
    }
  }

  &:hover {
    .writer-blog__title {
      color: var(--black-9);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .meta {
    gap: 50px;
    display: flex;
    padding-bottom: 10px;

    @media #{$md} {
      gap: 20px;
    }

    li {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.6;
      color: var(--black-9);
      text-transform: uppercase;
      position: relative;

      &:last-child {
        &::after {
          position: absolute;
          content: "";
          width: 1px;
          height: 10px;
          inset-inline-start: -25px;
          top: 6px;
          background-color: var(--black-9);

          @media #{$md} {
            inset-inline-start: -10px;
          }
        }
      }
    }
  }

  .thumb {
    overflow: hidden;
    margin-bottom: 30px;
    aspect-ratio: 100/124;

    @media #{$md} {
      margin-bottom: 20px;
    }
  }

  .title {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.4;
    color: var(--white);
    font-family: $font_prata;
    text-transform: uppercase;
    transition: all 0.3s;

    @include light {
      color: var(--black-2);
    }

    @media #{$xl} {
      font-size: 22px;
    }

    @media #{$md} {
      font-size: 20px;
    }

    &:hover {
      color: var(--primary);
    }
  }
}

.blog-details {
  @media #{$sm} {
    padding-top: 120px;
  }
  p {
    font-size: 18px;

    @media #{$sm} {
      font-size: 16px;
    }
  }
  .title {
    font-size: 100px;
    text-transform: uppercase;

    @media #{$lg} {
      font-size: 60px;
    }

    @media #{$md} {
      font-size: 50px;
    }

    @media #{$sm} {
      font-size: 35px;
    }
  }

  .thumb {
    img {
      width: 100%;
    }
  }

  .quote {
    background-color: #202226;
    padding: 30px 45px;
    position: relative;
    display: grid;
    grid-gap: 70px;
    grid-template-columns: 75px auto;

    @media #{$xl} {
      grid-gap: 50px;
    }

    @media #{$lg} {
      grid-gap: 40px;
    }

    @media #{$md} {
      grid-gap: 30px;
    }

    @media #{$sm} {
      gap: 0;
      grid-template-columns: 1fr;
      padding: 20px 30px;
      img {
        max-width: 60px;
        margin-bottom: 20px;
      }
    }

    &::before {
      position: absolute;
      content: "";
      inset-inline-start: 0;
      top: 0;
      height: 100%;
      width: 6px;
      background-color: var(--white);

      @include light {
        background-color: var(--black-2);
      }
    }

    @include light {
      background-color: var(--white-4);
    }

    .bottom-quote {
      position: absolute;
      inset-inline-end: 50px;
      bottom: 30px;

      @media #{$md} {
        display: none;
      }
    }

    .text {
      p {
        font-weight: 500;
        font-size: 28px;
        line-height: 1.3;
        color: var(--white);
        margin-bottom: 0;

        @include light {
          color: var(--black-2);
        }

        @media #{$lg} {
          font-size: 20px;

          br {
            display: none;
          }
        }

        @media #{$md} {
          font-size: 24px;
        }

        @media #{$sm} {
          font-size: 20px;
        }
      }
    }

    .author p {
      position: relative;
      padding-inline-start: 50px;

      &::before {
        content: "";
        position: absolute;
        inset-inline-start: 0;
        top: 13px;
        background: var(--gray);
        height: 1px;
        width: 40px;
      }
    }
  }

  .contentmiddle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    align-items: center;

    @media #{$md} {
      align-items: unset;
    }

    @media #{$sm} {
      grid-template-columns: 1fr;
    }
  }

  .content-title {
    color: var(--white);
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;

    @include light {
      color: var(--black-2);
    }

    @media #{$lg} {
      font-size: 32px;
    }

    @media #{$md} {
      margin-bottom: 30px;
    }

    @media #{$sm} {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  .share {
    border-top: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    padding-top: 20px;

    @include light {
      border-color: #eaeaea;
    }

    .tags {
      display: flex;
      gap: 8px;

      li {
        a {
          font-weight: 400;
          font-size: 12px;
          line-height: 1;
          color: var(--white);
          padding: 5px 10px;
          border-radius: 5px;
          background: var(--gray-10);

          @include light {
            color: var(--black-2);
            background: #e4e3e2;
          }

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    .social {
      display: flex;
      gap: 20px;
      align-items: center;

      @media #{$sm} {
        margin-bottom: 20px;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        color: var(--white);

        @include light {
          color: var(--black-2);
        }
      }

      .social-media {
        display: flex;
        gap: 12px;

        li {
          a {
            width: 12px;
            height: 12px;
            background-color: transparent;
            border: 0;
            color: var(--gray);

            @include light {
              color: #555555;
              background-color: var(--white);
            }

            &:hover {
              background-color: var(--white);
              color: var(--black-2);

              @include light {
                background-color: #555555;
                color: var(--white);
              }
            }
          }
        }
      }
    }

    // ul {
    //   li {
    //     a {
    //       background: #333437;
    //     }
    //   }
    // }
  }
}
