.scroll-top {
  width: 50px;
  height: 50px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9991;
  border-radius: 100px;
  color: var(--white);
  background: var(--white);
  transition: all 0.3s;
  display: none;
  mix-blend-mode: exclusion;
  // update v0.2 
  svg {
    font-size: 18px;
    color: var(--black-2);
  }

  &:hover {
    color: var(--gray-2);
  }

  @media #{$lg} {
    bottom: 80px;
  }

  @media #{$md} {
    right: 10px;
    bottom: 60px;
  }

  @media #{$sm} {
    right: 5px;
    bottom: 55px;
  }
}