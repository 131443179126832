.footer-section {
  .area {
    border-top: 1px solid #333337;

    @include light {
      border-color: #f1f1f1;
    }
  }

  .widgets-wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;
    grid-gap: 40px;

    @media #{$sm} {
      grid-template-columns: auto auto;
    }
  }

  .menu {
    a {
      font-size: 14px;
      color: var(--white);
      text-transform: uppercase;
      font-family: $font_glorifydemo;

      @include light {
        color: var(--black-2);
      }

      &:hover {
        color: var(--primary);
      }
    }
  }

  .copyright {
    &__area {
      border-top: 1px solid #333337;
      padding: 17px 0;

      @include light {
        border-color: #f1f1f1;
      }
    }

    &__inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
      align-items: center;

      @media #{$md} {
        grid-template-columns: auto auto auto;
      }

      @media #{$sm} {
        grid-template-columns: auto;
        grid-gap: 15px;
        justify-content: center;
      }
    }

    &__text {
      p {
        font-size: 14px;
        text-transform: uppercase;
        color: var(--white);
        font-family: $font_glorifydemo;

        @include light {
          color: var(--black-2);
        }

        a {
          color: var(--white);

          @include light {
            color: var(--black-2);
          }

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    &__social {
      display: flex;
      justify-content: flex-end;
      gap: 28px;

      @media #{$sm} {
        justify-content: center;
      }

      a {
        font-size: 14px;
        text-transform: uppercase;
        color: var(--white);
        font-family: $font_glorifydemo;

        @include light {
          color: var(--black-2);
        }

        &:hover {
          color: var(--primary);
        }

        &:not(:last-child) {
          position: relative;

          &:after {
            position: absolute;
            content: "";
            width: 1px;
            height: 12px;
            background-color: var(--white);
            inset-inline-end: -14px;
            top: 3px;

            @include light {
              background-color: var(--black-2);
            }
          }
        }
      }
    }
  }
}

.footer-section-2 {
  .area {
    border-top: 1px solid var(--black-5);

    @include light {
      border-color: var(--white-4);
    }

    .line {
      padding-top: 30px;
      padding-bottom: 30px;

      @media #{$md} {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media #{$sm} {
      grid-template-columns: 1fr;
      gap: 30px;
    }
  }

  .copyright {
    @include vertically_center;

    @media #{$sm} {
      grid-row: 3;
      text-align: center;
      justify-content: center;
    }

    p {
      a {
        color: var(--white);

        @include light {
          color: var(--black-2);
        }

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  .logo {
    text-align: center;
    position: relative;

    @media #{$sm} {
      grid-row: 1;
    }

    a {
      display: inline-block;
      padding: 30px 35px;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 25px 25px 0 0;
      border: 1px solid var(--black-5);
      background-color: var(--black-2);

      @include light {
        border-color: var(--gray-2);
        background-color: var(--white);
      }

      @media #{$sm} {
        border-radius: 25px;
      }
    }

    img {
      @media #{$md} {
        height: auto !important;
      }
    }
  }

  .social {
    @include vertically_center;
    justify-content: flex-end;
    gap: 20px;

    @media #{$sm} {
      grid-row: 2;
      margin-top: 20px;
      justify-content: center;
      margin-bottom: 20px;
    }

    li {
      display: inline-block;

      a {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: var(--white);
        text-transform: capitalize;

        @include light {
          color: var(--black-2);
        }

        &:hover {
          color: var(--gray-4);
        }
      }
    }
  }
}
