.offcanvas-3 {
  .area {
    background: var(--black-2);
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0 50px;
    z-index: 1;
    overflow: hidden;

    // @media (max-height: 500px) {
    //   padding: 40px;
    // }
  }

  .inner {
    display: grid;
    grid-template-columns: 340px 1fr;
    height: 100%;

    @media #{$md} {
      grid-template-columns: 260px 1fr;
    }

    @media #{$sm} {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
    }

    @media (max-height: 500px) {
      overflow-y: scroll;
    }
  }

  .meta {
    li {
      font-size: 18px;
      line-height: 20px;
      color: var(--white);
      text-transform: uppercase;
      font-family: $font_glorifydemo;
      margin-inline-end: 40px;

      &:not(:last-child) {
        margin-bottom: 19px;
      }
    }

    a {
      &:hover {
        color: var(--primary);
      }
    }
  }

  .meta-wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 100px;

    @media #{$sm} {
      padding-top: 40px;
      padding-bottom: 0px;
    }
  }

  .social {
    .title {
      font-size: 18px;
      line-height: 20px;
      color: var(--white);
      text-transform: uppercase;
      font-family: $font_glorifydemo;
      margin-bottom: 10px;
    }
  }

  .social-links {
    display: flex;
    gap: 20px;

    a {
      font-size: 18px;
      color: var(--white);

      &:hover {
        color: var(--primary);
      }
    }
  }

  .menu {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 1px;
      background-color: var(--white);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray-6);
    }

    li {
      padding-left: 58px;
      position: relative;

      @media #{$sm} {
        padding-left: 0;
      }

      &:hover {
        > a {
          color: var(--white);
        }

        &:before {
          opacity: 1;
          visibility: visible;
        }
      }

      &:before {
        position: absolute;
        content: "";
        width: 17px;
        height: 17px;
        background: var(--white);
        border-radius: 50%;
        border: 4px solid var(--black-2);
        left: 0;
        top: 38%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;

        @media #{$sm} {
          display: none;
        }
      }

      &:not(:last-child) {
        margin-bottom: 26px;
      }

      a {
        font-size: 120px;
        font-size: 8vh;
        color: #666666;
        font-family: $font_glorifydemo;
        text-transform: uppercase;
        line-height: 0.9;
        position: relative;
        transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
        text-decoration: none;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(90deg, #fff, #ddd 50%, #666 0);
        background-size: 200% 100%;
        background-position: 100%;
        transform: perspective(359px) rotateY(-18deg);

        &:hover {
          background-position: 0;
          color: #fff;
          letter-spacing: 1.5px;
        }

        @media #{$xl} {
          font-size: 7vh;
        }

        @media #{$lg} {
          font-size: 6vh;
        }

        @media #{$md} {
          font-size: 5vw;
        }
      }
    }
  }

  .menu-wrapper {
    display: flex;
    align-items: flex-end;
    overflow-y: hidden;
    position: relative;
    padding-top: 50px;
    padding-bottom: 100px;

    @media #{$sm} {
      padding-top: 0px;
      padding-bottom: 40px;
    }

    &:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 100%;
      background: #333337;
      left: 8px;
      top: 0;

      @media #{$sm} {
        display: none;
      }

      @media (max-height: 500px) {
        height: 100%;
      }
    }
  }

  .close-button {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: var(--black-2);
    position: relative;
    border: 1px solid #333337;

    @media #{$sm} {
      width: 50px;
      height: 50px;
    }

    span {
      width: 26px;
      height: 1px;
      display: inline-block;
      background-color: var(--white);
      position: absolute;
      left: 50%;
      top: 50%;

      @media #{$sm} {
        width: 20px;
      }

      &:first-child {
        transform: translateX(-50%) rotate(45deg);
      }

      &:last-child {
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }
}
