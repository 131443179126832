.header-section {
  .area {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50px;
    inset-inline-start: 0;

    @media #{$sm} {
      top: 40px;
    }
  }

  .wrapper {
    gap: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 120px;

    @media #{$xxl} {
      padding: 0 80px;
    }

    @media #{$lg} {
      padding: 0 40px;
    }

    @media #{$sm} {
      padding: 0 15px;
    }
  }

  .logo {
    img {
      max-width: 160px;
    }
  }
}
