.service-section {
  .area {
    .shape {
      position: absolute;
      bottom: -12px;
      inset-inline-start: calc(33.33% - 11px);
      z-index: 1;

      &-2 {
        position: absolute;
        bottom: -12px;
        inset-inline-start: calc(66.66% - 11px);
        z-index: 1;
      }
    }

    .line {
      border-top: 1px solid var(--black-5);

      @include light {
        border-color: var(--white-4);
      }
    }

    .sec-title-5 {
      padding-bottom: 60px;

      @media #{$sm} {
        padding-bottom: 50px;
      }
    }

    .sec-title {
      @media #{$sm} {
        margin-top: 50px;
      }
    }
  }

  .list {
    border-top: 1px solid var(--black-6);

    @include light {
      border-color: var(--gray-2);
    }

    .item {
      padding: 40px 0;
      display: grid;
      grid-template-columns: 0.25fr 1fr 1fr 0.4fr;
      border-bottom: 1px solid var(--black-6);
      gap: 15px;

      @include light {
        border-color: var(--gray-2);
      }

      @media #{$xxl} {
        grid-template-columns: 0.25fr 1fr 1fr 0.6fr;
      }

      @media #{$lg} {
        grid-template-columns: 0.15fr 0.7fr 1fr 0.7fr;
      }

      @media #{$sm} {
        padding: 25px 0;
        grid-template-columns: 1fr;
      }

      @media (min-width: 1300px) {
        &:hover {
          .title {
            padding-inline-start: 80px;

            &::before {
              width: 60px;
            }
          }

          .number {
            color: var(--white);

            @include light {
              color: var(--black-2);
            }
          }
        }
      }

      p {
        max-width: 330px;
      }

      ul li {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;
        color: var(--white);

        @include light {
          color: var(--black-2);
        }
      }
    }

    .number {
      @include title(var(--gray-4), 18px);
      transition: all 0.3s;
    }

    .title {
      @include title_md(var(--white));
      line-height: 1.2;
      transition: all 0.5s;
      position: relative;
      max-width: 200px;

      @include light {
        color: var(--black-2);
      }

      @media #{$lg} {
        font-size: 22px;
      }

      &::before {
        position: absolute;
        content: "";
        width: 0;
        height: 1px;
        inset-inline-start: 0;
        top: 5px;
        transition: all 0.5s;
        background-color: var(--white);

        @include light {
          background-color: var(--black-2);
        }
      }
    }
  }
}

.service-section-2 {
  .area {
    position: relative;

    &.dark {
      background: #212125;

      @include light {
        background: var(--black-2);
      }

      .side-title {
        color: #999999;
      }

      .sec-sub-title-18 {
        color: var(--white);
      }

      .sec-title-18 {
        color: var(--white);
      }

      .active {
        .name {
          .count {
            color: var(--white);
          }

          .title {
            color: var(--white);
          }

          .number {
            color: var(--white);
          }
        }
      }

      .wc-btn-default {
        color: var(--white);

        &:hover {
          color: var(--black-2);
          border-color: transparent;
        }
      }
    }

    .sec-title-wrapper {
      .sec-sub-title-18 {
        margin-bottom: 50px;

        @media #{$sm} {
          margin-bottom: 20px;
        }
      }
    }

    .items {
      position: relative;
      min-height: 720px;

      @media #{$lg} {
        min-height: 610px;
      }

      .nav {
        display: block;
        border-bottom: 0;
      }

      .nav-item {
        &:not(:last-child) {
          margin-bottom: 44px;

          @media #{$md} {
            margin-bottom: 35px;
          }
          @media #{$xs} {
            margin-bottom: 24px;
          }
        }
      }

      .nav-link {
        padding: 0;
        border: 0;

        &.active {
          background-color: transparent;
        }
      }
    }

    .name-wrapper {
      margin-bottom: 55px;

      .name {
        display: grid;
        grid-template-columns: 80px auto;

        @media #{$xs} {
          grid-template-columns: 50px auto;
        }

        &:not(:last-child) {
          margin-bottom: 44px;

          @media #{$xs} {
            margin-bottom: 24px;
          }
        }
      }

      .number {
        font-size: 20px;
        color: #999999;
        font-family: $font_glorifydemo;
        line-height: 1;
        text-align: start;
      }

      .title {
        font-size: 50px;
        color: #999999;
        line-height: 1;
        text-transform: uppercase;

        @include light {
          color: #999999;
        }

        @media #{$lg} {
          font-size: 40px;
        }

        @media #{$xs} {
          font-size: 30px;
        }
      }
    }

    .content {
      .hoverImg {
        margin-bottom: 43px;
        position: absolute;
        top: 0;
        inset-inline-end: 0;
        max-width: 520px;

        @media #{$lg} {
          max-width: 440px;
        }

        @media #{$md} {
          position: static;
        }
      }

      .text {
        p {
          font-size: 18px;
          max-width: 440px;
          color: #999999;

          @include light {
            color: #999999;
          }
        }
      }

      .service-btn {
        margin-top: 63px;

        .wc-btn-default {
            padding: 27px 45px;
            text-transform: uppercase;
        }
    }
    }
  }
}

.service-details {
  .main-title {
    @media #{$sm} {
      margin-top: 70px;
    }

    .sec-title-18 {
      @media #{$sm} {
        font-size: 40px;
      }
    }
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    gap: 140px;
    padding-top: 90px;

    @media #{$lg} {
      gap: 50px;
    }

    @media #{$sm} {
      grid-template-columns: 1fr;
      padding-top: 50px;
    }

    p {
      font-size: 18px;
      line-height: 1.5;

      @media #{$sm} {
        font-size: 16px;
      }
    }

    .title {
      font-size: 60px;
      font-weight: 400;

      @media #{$lg} {
        font-size: 45px;
      }

      @media #{$md} {
        font-size: 36px;
      }

      @media #{$sm} {
        font-size: 30px;
      }
    }

    .sub-title {
      font-size: 40px;

      @media #{$sm} {
        font-size: 30px;
      }
    }
  }

  .cf_parallax_image {
    text-align: center;
    img {
      object-fit: cover;
      margin: auto;
    }
  }
}

.other-service {
  background-color: #212224;
  padding: 40px;

  @include light {
    background-color: #fafafa;
  }

  @media #{$md} {
    padding: 20px;
  }

  .title {
    font-size: 30px !important;
    text-transform: uppercase;

    @media #{$md} {
      font-size: 20px;
    }

    @include light {
      color: var(--black-2);
    }
  }

  ul {
    li {
      border-bottom: 1px solid #3f3f3f;

      &:last-child {
        border: 0;

        a {
          padding-bottom: 0;
        }
      }

      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;
        color: var(--gray-10);
        font-size: 20px;

        @include light {
          border-color: #e9e9e9;
        }

        svg {
          opacity: 0;
          visibility: hidden;
          transition: 0.7s;
        }

        &:hover {
          color: var(--white);

          @include light {
            color: var(--black-2);
          }

          svg {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }
  }
}
