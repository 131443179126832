.home-hero {
  .area {
    padding: 20px 20px 0;

    @media #{$sm} {
      padding: 15px 10px 0;
    }
  }

  .wrapper {
    padding: 140px 90px;
    background-color: #d7d7d7;
    position: relative;
    border-radius: 30px;
    overflow: hidden;

    @media #{$xxl} {
      padding: 105px 50px;
    }

    @media #{$xl} {
      padding: 60px 50px;
    }

    @media #{$lg} {
      padding: 76px 20px;
    }

    @media #{$sm} {
      padding: 50px 10px;
    }

    .img {
      position: absolute;
      bottom: 0;
      inset-inline-start: 290px;

      @media #{$xxl} {
        max-width: 700px;
        inset-inline-start: 200px;
      }

      @media #{$xl} {
        max-width: 560px;
        inset-inline-start: 240px;
      }

      @media #{$lg} {
        max-width: 460px;
        inset-inline-start: 140px;
      }

      @media #{$md} {
        max-width: 320px;
        inset-inline-start: 80px;
      }

      @media #{$sm} {
        max-width: 270px;
        inset-inline-start: unset;
        inset-inline-end: 0;
      }
    }

    .shape {
      position: absolute;
      inset-inline-start: 310px;
      bottom: 260px;

      @media #{$xxl} {
        inset-inline-start: 240px;
        bottom: 160px;
      }

      @media #{$xl} {
        inset-inline-start: 200px;
        bottom: 60px;
      }

      @media #{$lg} {
        inset-inline-start: 130px;
        bottom: 60px;
      }

      @media #{$md} {
        display: none;
      }

      &-2 {
        position: absolute;
        inset-inline-start: 60%;
        bottom: 40px;

        @media #{$xxl} {
          inset-inline-start: 65%;
        }

        @media #{$xl} {
          inset-inline-start: 60%;
          bottom: 30px;
        }

        @media #{$sm} {
          bottom: 10px;
        }
      }

      &-3 {
        position: absolute;
        inset-inline-end: 80px;
        top: 52%;

        @media #{$sm} {
          inset-inline-end: 20px;
        }
      }
    }
  }

  .title {
    font-weight: 500;
    font-size: 150px;
    line-height: 1;
    color: var(--black-2);

    @media #{$xxl} {
      font-size: 110px;
    }

    @media #{$lg} {
      font-size: 80px;
    }

    @media #{$md} {
      font-size: 60px;
    }

    br {
      @media #{$sm} {
        display: block;
      }
    }

    span {
      font-weight: 400;
      font-family: $font_newyork;
    }

    &-wrap {
      position: relative;

      .text-pos {
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        color: var(--black-2);
        border: 3px solid var(--black-2);
        border-radius: 12px;
        position: absolute;
        padding: 8px 6px;
        inset-inline-end: 40px;
        top: 60px;

        @media #{$xxl} {
          top: 30px;
        }

        @media #{$lg} {
          top: 20px;
          font-size: 20px;
        }

        @media #{$md} {
          font-size: 16px;
          border-width: 2px;
          padding: 4px 4px;
        }

        @media #{$sm} {
          left: 230px;
          right: auto;
        }
      }
    }
  }

  .sub-title {
    font-weight: 400;
    font-size: 30px;
    line-height: 1.1;
    color: var(--black-2);
    position: relative;

    @media #{$md} {
      font-size: 18px;
    }

    &::before {
      position: absolute;
      content: "";
      width: 110px;
      height: 1px;
      inset-inline-start: -150px;
      top: 18px;
      background: var(--black-2);

      @media #{$md} {
        top: 12px;
        inset-inline-start: -80px;
        width: 60px;
      }

      @media #{$sm} {
        top: 12px;
        width: 60px;
      }
    }

    &-pd {
      // padding: 12px 0 75px 150px;

      padding-inline-start: 150px;
      padding-top: 12px;
      padding-bottom: 75px;

      @media #{$xl} {
        padding-bottom: 40px;
      }

      @media #{$md} {
        padding-inline-start: 120px;
        padding-bottom: 50px;
      }

      @media #{$md} {
        padding-inline-start: 80px;
      }

      @media #{$sm} {
        padding-top: 20px;
      }
    }
  }

  .left {
    .btn-width {
      width: 230px;
      padding-top: 50px;

      @media #{$xl} {
        padding-top: 70px;
      }
    }

    .wc-btn-underline {
      font-size: 18px;
      color: var(--black-2);
      gap: 50px;

      img {
        transform: rotate(-45deg);

        @include rtl {
          transform: rotate(-145deg);
        }
      }

      &:after {
        background-color: var(--black-2);
      }
    }
  }

  .experience {
    margin-top: 140px;

    @media #{$xxl} {
      margin-top: 100px;
    }

    @media #{$xl} {
      margin-top: 80px;
    }

    @media #{$lg} {
      margin-top: 80px;
    }

    @media #{$sm} {
      margin-top: 60px;
    }
  }

  .right {
    position: relative;
    z-index: 9;

    .work_process-3-mg {
      margin-inline-start: 150px;
      min-height: 85px;

      @media #{$md} {
        margin-inline-start: 20px;
      }
    }
  }

  .text {
    padding-top: 40px;
    padding-inline-start: 150px;

    @media #{$xl} {
      padding-top: 30px;
    }

    @media #{$md} {
      padding-inline-start: 20px;
      position: relative;
      z-index: 9;
    }

    p {
      max-width: 300px;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      color: var(--black-2);

      @media #{$xl} {
        max-width: 360px;
      }
    }
  }
}

.home-hero-2 {
  .area {
    position: relative;
    z-index: 1;
  }

  .area-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    inset-inline-start: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    top: 0;
    inset-inline-start: 0;
    z-index: 2;
    opacity: 0.2;
  }

  .content-wrapper {
    margin-top: 100px;
    margin-bottom: 190px;

    @media #{$xxl} {
      margin-bottom: 100px;
    }
  }

  .title {
    font-size: 170px;
    line-height: 1;
    color: var(--white);
    text-transform: uppercase;
    text-align: center;

    @include light {
      color: var(--white);
    }

    @media #{$xxl} {
      font-size: 140px;
    }

    @media #{$xl} {
      font-size: 120px;
    }

    @media #{$lg} {
      font-size: 100px;
    }

    @media #{$md} {
      font-size: 90px;
    }

    @media #{$sm} {
      font-size: 60px;
    }
  }

  .subtitle {
    font-size: 24px;
    color: var(--white);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 45px;

    @include light {
      color: var(--white);
    }
  }

  .middle-color {
    @include light {
      color: var(--primary);
    }
  }

  .last-color {
    @include light {
      color: var(--black-2);
    }
  }
}

.about-hero {
  .area {
    position: relative;
    z-index: 1;
    margin-top: 80px;

    .area-bg {
      position: absolute;
      width: calc(100% - 200px);
      height: calc(100% - 200px);
      top: 100px;
      inset-inline-start: 100px;
      z-index: -1;

      @media #{$xxl} {
        width: calc(100% - 140px);
        height: calc(100% - 140px);
        top: 70px;
        inset-inline-start: 70px;
      }

      @media #{$sm} {
        width: calc(100% - 0px);
        height: calc(100% - 0px);
        top: 0;
        inset-inline-start: 0;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      position: relative;
      z-index: 1;

      .heading-wrapper {
        margin-bottom: 50px;

        .title {
          font-size: 130px;
          text-transform: uppercase;

          @media #{$xl} {
            font-size: 115px;
          }

          @media #{$lg} {
            font-size: 80px;
          }

          @media #{$md} {
            font-size: 70px;
          }

          @media #{$sm} {
            font-size: 40px;
          }
        }

        .sub-title {
          font-size: 24px;
          color: var(--white);
          text-transform: uppercase;

          @include light {
            color: var(--black-2);
          }
        }
      }

      .inner {
        display: grid;
        align-items: center;
        grid-template-columns: 60% 1fr;
        grid-gap: 110px;

        @media #{$xl} {
          grid-template-columns: 50% 1fr;
        }

        @media #{$lg} {
          align-items: start;
          grid-gap: 60px;
        }

        @media #{$sm} {
          grid-template-columns: 1fr;
        }

        .thumb {
          position: relative;
        }

        .sticker {
          margin-top: 95px;

          @media #{$lg} {
            margin-top: 30px;
          }

          @media #{$sm} {
            margin-top: 0px;
          }
        }

        .text {
          p {
            font-size: 18px;

            @include light {
              color: #555555;
            }

            @media #{$sm} {
              font-size: 16px;
            }
          }
        }
      }
    }

    .arrow-line-icon {
      padding-inline-start: 140px;
      padding-bottom: 50px;

      @media #{$xl} {
        padding-inline-start: 0;
        padding-bottom: 0;
      }

      @media #{$lg} {
        display: none;
      }

      img {
        @include rtl {
          transform: rotateY(180deg);
        }
      }
    }
  }
}
