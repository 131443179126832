.vertical-separator {
  .line-vertical {
    width: 1px;
    height: 100px;
    background: var(--white);
    margin: 0 auto;

    @include light {
        background: var(--black-2);
    }
}
}