.comment-section {
  .title {
    font-size: 36px;
    font-weight: 500;
    line-height: 1;

    @media #{$sm} {
      font-size: 25px;
    }
  }

  .form {
    padding-top: 65px;
  }

  .field {
    label {
      display: block;
      margin-bottom: 15px;
      color: var(--gray-4);
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;

      @include light {
        color: #555555;
      }
    }

    input {
      width: 100%;
      height: 60px;
      padding: 20px;
      background-color: #212125;
      border: 0;
      color: var(--white);
      @include light {
        background-color: #f5f5f5;
        color: var(--black-2);
      }

      &::placeholder {
        color: #bebebe;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;

        @include light {
          color: #999999;
        }
      }

      &:focus {
        outline: 0;
      }
    }

    textarea {
      @extend input;
      height: 120px;
      resize: none;
    }
  }

  .btn-wrapper {
    .wc-btn-default {
      font-size: 18px;
      font-weight: 600;
      padding: 30px 70px;
      background-color: var(--white);
      color: var(--black-2);
      text-transform: uppercase;
      border-color: transparent;

      @include light {
        background-color: var(--black-2);
        color: var(--white);
      }

      @media #{$lg} {
        padding: 20px 40px;
        font-weight: 500;
      }

      &:hover {
        background: transparent;

        @include light {
          color: var(--black-2);
        }
      }
    }
  }
}
