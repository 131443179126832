header {
  .area {
    margin-bottom: -1px;
    z-index: 100;
  }
}

.zi {
  &-1 {
    z-index: 1;
    position: relative;
  }
}

.work_experience {
  &-img {
    gap: 30px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 6;

    @media #{$lg} {
      margin-top: 80px;
      gap: 20px;
    }

    @media #{$sm} {
      margin-top: 50px;
      margin-bottom: 30px;

      img {
        max-width: 70px;
      }
    }

    .exp-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      color: var(--black-2);
      width: 15%;

      span {
        display: block;
        font-size: 60px;
        line-height: 1;
        text-transform: uppercase;

        @media #{$md} {
          font-size: 48px;
        }
      }

      @media #{$md} {
        width: 90px;
      }
      @media #{$sm} {
        width: 100%;
      }
    }

    .image-half {
      max-width: 100px;

      @media #{$md} {
        max-width: 60px;
      }
    }
  }
}

.work_process {
  &-3 {
    .play-btn {
      width: 85px;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--black-2);
      border: 1px solid currentColor;
      border-radius: 50px;
      transition: all 0.3s;

      &:hover {
        color: var(--primary);
      }
    }
  }
}

.modal-content {
  width: auto;
}

.sec-title {
  &-5 {
    font-weight: 600;
    font-size: 65px;
    line-height: 1.1;

    @media #{$xl} {
      font-size: 60px;
    }

    @media #{$lg} {
      font-size: 48px;
    }

    span {
      display: block;
      font-weight: 400;
      font-family: $font_newyork;
      padding-inline-start: 100px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        inset-inline-start: 0;
        top: 50%;
        width: 80px;
        height: 1px;
        background-color: var(--white);

        @include light {
          background-color: var(--black-2);
        }
      }
    }
  }

  &-18 {
    font-size: 170px;
    line-height: 1;
    font-weight: 400;
    text-transform: uppercase;

    @media #{$xxl} {
      font-size: 140px;
    }

    @media #{$xl} {
      font-size: 120px;
    }

    @media #{$lg} {
      font-size: 90px;
    }

    @media #{$md} {
      font-size: 70px;
    }

    @media #{$sm} {
      font-size: 60px;
    }
  }
}

.sec-sub-title {
  &-18 {
    font-size: 24px;
    text-transform: uppercase;
  }
}

.text_invert > div {
  background-image: linear-gradient(
    to right,
    rgb(255, 255, 255) 50%,
    rgb(66 60 60) 50%
  );
  background-size: 200% 100%;
  background-position-x: 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;

  @include light {
    background-image: linear-gradient(
      to right,
      rgb(0, 0, 0) 50%,
      rgb(66 60 60 / 50%) 50%
    );
  }
}

.list-plus {
  li {
    position: relative;
    padding-inline-start: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;

    &::before {
      content: "+";
      position: absolute;
      inset-inline-start: 0;
      top: -10px;
      font-size: 30px;
      font-weight: 300;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.list-check {
  li {
    position: relative;
    padding-inline-start: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 10px;

    &::before {
      content: "";
      position: absolute;
      inset-inline-start: 0;
      background-image: url("/assets/imgs/common/check-mark.png");
      background-repeat: no-repeat;
      width: 14px;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);

      @include rtl {
        transform: translateY(-50%) rotateY(180deg);
      }

      @include light {
        background-image: url("/assets/imgs/common/check-mark-dark.png");
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

// Position Absolute
.pos-abs {
  position: absolute !important;
  width: 100%;
  height: auto;
  inset-inline-start: 0;
  top: 0;
}

.video {
  &__full {
    img {
      width: 100%;
      height: 750px;
      object-fit: cover;

      @media #{$xl} {
        height: 700px;
      }

      @media #{$lg} {
        max-height: 500px;
      }

      @media #{$md} {
        height: 400px;
      }

      @media #{$sm} {
        height: 300px;
      }
    }
  }
}

.sm-line {
  position: relative;
  padding-inline-start: 20px;
  padding-inline-end: 20px;

  &::before {
    position: absolute;
    content: "";
    inset-inline-start: 20px;
    top: 4px;
    height: 10px;
    width: 1px;
    background-color: currentColor;
  }
}

.cf_parallax_image {
  overflow: hidden;
}

.modal-content {
  width: auto;
}

.video-modal {
  .modal-content {
    background-color: transparent;
    border: none;

    .btn-close {
      background-color: white;
    }
  }
}

.side-title {
  font-size: 20px;
  color: #999999;
  writing-mode: vertical-lr;
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  text-transform: uppercase;

  @media #{$xxl} {
    left: 20px;
  }

  @media #{$lg} {
    left: 10px;
  }

  @media #{$sm} {
    display: none;
  }
}

.anim_img {
  &_zoom {
    overflow: hidden;

    img {
      transform: scale(1);
      transition: all 1s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}

.video {
  &__full {
    video {
      width: 100%;
      height: 750px;
      object-fit: cover;
      margin-bottom: -6px;

      @media #{$xl} {
        height: 700px;
      }

      @media #{$lg} {
        height: 450px;
      }

      @media #{$md} {
        height: 400px;
      }

      @media #{$sm} {
        height: 300px;
      }
    }

    iframe {
      width: 100%;
      height: 750px;
      object-fit: cover;
      margin-bottom: -6px;

      @media #{$xl} {
        height: 700px;
      }

      @media #{$lg} {
        height: auto;
      }

      @media #{$md} {
        height: auto;
      }

      @media #{$sm} {
        height: auto;
      }
    }

    img {
      width: 100%;
      height: 750px;
      object-fit: cover;

      @media #{$xl} {
        height: 700px;
      }

      @media #{$lg} {
        max-height: 500px;
      }

      @media #{$md} {
        height: 400px;
      }

      @media #{$sm} {
        height: 300px;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
