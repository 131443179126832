.cta-section {
  .area {
    text-align: center;

    .line {
      border-top: 1px solid var(--black-5);
      border-bottom: 1px solid var(--black-5);

      @include light {
        border-color: var(--white-4);
      }
    }

    .shape {
      position: absolute;
      bottom: -12px;
      inset-inline-start: calc(33.33% - 11px);
      z-index: 1;

      &-2 {
        position: absolute;
        bottom: -12px;
        inset-inline-start: calc(66.66% - 11px);
        z-index: 1;
      }
    }

    .cf_btn {
      svg {
        @include rtl {
          transform: rotateY(180deg);
        }
      }
    }
  }

  .content {
    position: relative;
    z-index: 1;
  }

  .title {
    font-weight: 600;
    font-size: 65px;
    line-height: 1.1;
    color: var(--white);

    @media #{$md} {
      font-size: 48px;
    }

    @media #{$sm} {
      font-size: 36px;
    }

    span {
      font-family: $font_newyork;
      font-weight: 400;
    }

    &-pd {
      max-width: 510px;
      margin: 0 auto;
      margin-bottom: 55px;
    }
  }

  .wc-btn-border {
    &:hover {
      color: var(--black-2);
    }
  }
}

.cta-section-2 {
  .wrapper {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between;
    grid-gap: 45px;

    @media #{$md} {
      grid-template-columns: 1fr;
    }
  }

  .title-wrapper {
    position: relative;

    .sec-title-18 {
      margin-bottom: -30px;

      @media #{$sm} {
        margin-bottom: 20px;
        font-size: 40px;
      }
    }
  }

  .text {
    max-width: 250px;
    position: absolute;
    inset-inline-end: -30px;
    top: 50%;
    transform: translateY(-50%);

    @media #{$lg} {
      inset-inline-end: -110px;
    }

    @media #{$md} {
      inset-inline-end: 0;
      max-width: 270px;
    }

    @media #{$sm} {
      position: static;
      transform: none;
    }

    p {
      font-size: 18px;

      @include light {
        color: #555555;
      }
    }
  }

  .cta-btn {
    .wc-btn-default {
      font-size: 18px;
      font-weight: 600;
      padding: 35px 75px;
      background-color: var(--white);
      color: var(--black-2);
      text-transform: uppercase;
      border-color: transparent;

      @include light {
        background-color: var(--black-2);
        color: var(--white);
      }

      @media #{$lg} {
        padding: 20px 40px;
        font-weight: 500;
      }

      &:hover {
        background: transparent;

        @include light {
          color: var(--black-2);
        }
      }
    }
  }
}
