// Button Animation KeyFrame
@keyframes mask_animation {
  from {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }

  to {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }
}

@keyframes mask_animation_2 {
  from {
    -webkit-mask-position: 100% 0;
    mask-position: 100% 0;
  }

  to {
    -webkit-mask-position: 0 0;
    mask-position: 0 0;
  }
}


// 
// Button Hover Animation
// 
.btn {
  &-hover {

    &-default {
      transition: all 0.5s;

      &:hover {
        color: var(--black-2);
        background-color: var(--white);
      }
    }

    &-cross {
      overflow: hidden;
      position: relative;
      transition: all 1s;

      &::after {
        position: absolute;
        content: "";
        width: 150%;
        height: 0%;
        left: 50%;
        top: 50%;
        background-color: var(--primary);
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
        transition: all 0.75s;
        opacity: 0.5;
        z-index: -1;
      }

      &:hover {
        border-color: var(--primary);
        background-color: transparent;

        &::after {
          height: 120%;
          opacity: 1;
        }
      }
    }

    &-divide {
      overflow: hidden;
      position: relative;
      transition: all 1s;
      z-index: 1;

      &::after {
        position: absolute;
        content: "";
        width: 150%;
        height: 0%;
        left: 50%;
        top: 50%;
        background-color: var(--primary);
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
        transition: all 0.75s;
        opacity: 0.5;
        z-index: -1;
      }

      &:hover {
        border-color: var(--primary);
        background-color: transparent;

        &::after {
          height: 400%;
          opacity: 1;
        }
      }
    }

    &-cropping {
      overflow: hidden;
      position: relative;
      transition: all 1s;

      &::after {
        position: absolute;
        content: "";
        width: 150%;
        height: 0%;
        left: 50%;
        top: 50%;
        background-color: var(--primary);
        transform: translateX(-50%) translateY(-50%) rotate(25deg);
        transition: all 0.75s;
        opacity: 0.5;
        z-index: -1;
      }

      &:hover {
        border-color: var(--primary);
        background-color: transparent;

        &::after {
          height: 400%;
          opacity: 1;
        }
      }
    }

    &-mask {
      gap: 10px;
      display: inline-flex;
      align-items: center;
      padding: 15px 30px;
      position: relative;
      overflow: hidden;
      transition: all 0.5s;
      border-radius: 5px;
      color: var(--white);
      font-weight: 400;
      font-size: 16px;
      border: 1px solid var(--white);
      z-index: 1;

      &::after {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black-2);
        position: absolute;
        content: attr(data-text);
        cursor: pointer;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: var(--white);
        mask: url("https://raw.githubusercontent.com/robin-dela/css-mask-animation/master/img/nature-sprite.png");
        mask-size: 2300% 100%;
        animation: mask_animation_2 0.7s steps(22) forwards;
      }

      &:hover {
        color: var(--white);

        &::after {
          animation: mask_animation 0.7s steps(22) forwards;
        }
      }

      @include light {
        border-color: var(--black-2);

        &::after {
          z-index: -1;
          color: var(--white);
          background-color: var(--black-2);
        }

        &:hover {
          color: var(--black-2);
        }
      }
    }
  }

  &-rollover {
    &-top {
      position: relative;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

      &:before {
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 0px;
        width: 100%;
        z-index: -1;
        content: '';
        background-color: var(--primary);
        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }

      &:hover {
        border-color: var(--primary);
        background-color: transparent;

        &::before {
          top: 0%;
          bottom: auto;
          height: 100%;
        }
      }
    }

    &-left {
      position: relative;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

      &::before {
        position: absolute;
        top: 0px;
        right: 0px;
        height: 100%;
        width: 0px;
        z-index: -1;
        content: '';
        background-color: var(--primary);
        transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1) 0s;
      }

      &:hover {
        border-color: var(--primary);
        background-color: transparent;

        &::before {
          left: 0%;
          right: auto;
          width: 100%;
        }
      }
    }

    &-cross {
      overflow: hidden;
      position: relative;
      transition: all 0.5s;

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        bottom: 100%;
        left: 100%;
        opacity: 0;
        border-bottom: 3px solid var(--primary);
        border-left: 3px solid var(--primary);
        transition: all 0.75s;
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 100%;
        right: 100%;
        opacity: 0;
        border-top: 3px solid var(--primary);
        border-right: 3px solid var(--primary);
        transition: all 0.75s;
      }

      &:hover {
        border-color: transparent;
        color: var(--primary);

        &::before {
          bottom: 0;
          left: 0;
          opacity: 1;
          width: 100%;
          height: 100%;
        }

        &::after {
          top: 0;
          right: 0;
          opacity: 1;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-parallal-border {
    overflow: hidden;
    position: relative;
    transition: all 0.5s;

    &::before {
      position: absolute;
      content: "";
      width: 0%;
      height: 0%;
      bottom: 0;
      left: 0;
      opacity: 0;
      border-bottom: 3px solid var(--primary);
      border-left: 3px solid var(--primary);
      border-radius: 5px;
      transition: all 0.75s;
    }

    &::after {
      position: absolute;
      content: "";
      width: 0%;
      height: 0%;
      top: 0;
      right: 0;
      opacity: 0;
      border-top: 3px solid var(--primary);
      border-right: 3px solid var(--primary);
      border-radius: 5px;
      transition: all 0.75s;
    }

    &:hover {
      border-color: transparent;
      color: var(--primary);

      &::before {
        opacity: 1;
        width: 100%;
        height: 100%;
      }

      &::after {
        opacity: 1;
        width: 100%;
        height: 100%;
      }
    }
  }
}