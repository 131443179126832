.contact-section {
  .content {
    width: 75%;
    display: grid;
    grid-template-columns: 1.3fr 0.7fr;
    gap: 50px;
    align-items: center;
    margin-top: 80px;

    @media #{$md} {
      width: 100%;
      grid-template-columns: 1fr 1fr;
    }

    @media #{$sm} {
      width: 100%;
      grid-template-columns: 1fr;
      gap: 20px;
    }

    .title {
      font-size: 150px;
      font-weight: 400;
      line-height: 1;
      display: block;
      padding-bottom: 30px;

      @media #{$lg} {
        font-size: 120px;
      }

      @media #{$md} {
        font-size: 80px;
      }

      @media #{$sm} {
        font-size: 60px;
      }
    }
  }

  .form {
    .field {
      label {
        display: block;
        margin-bottom: 15px;
        color: var(--gray-4);
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        text-transform: capitalize;

        @include light {
          color: #555555;
        }
      }

      input {
        width: 100%;
        height: 60px;
        padding: 20px;
        background-color: #212125;
        border: 0;
        color: var(--white);
        @include light {
          background-color: #f5f5f5;
          color: var(--black-2);
        }

        &::placeholder {
          color: #bebebe;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;

          @include light {
            color: #999999;
          }
        }

        &:focus {
          outline: 0;
        }
      }

      textarea {
        @extend input;
        height: 120px;
        resize: none;
      }
    }

    .contact-btn-wrapper {
      .wc-btn-default {
        font-size: 18px;
        font-weight: 600;
        padding: 30px 70px;
        background-color: var(--white);
        color: var(--black-2);
        text-transform: uppercase;
        border-color: transparent;

        @include light {
          background-color: var(--black-2);
          color: var(--white);
        }

        @media #{$lg} {
          padding: 20px 40px;
          font-weight: 500;
        }

        &:hover {
          background: transparent;

          @include light {
            color: var(--black-2);
          }
        }
      }
    }
  }
}
