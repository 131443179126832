/* Preloader */
.container-preloader {
  align-items: center;
  cursor: none;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 900;

  .animation-preloader {
    position: absolute;
    z-index: 100;

    .spinner {
      animation: spinner 1s infinite linear;
      border-radius: 50%;
      height: 9em;
      width: 9em;
      border: 10px solid rgba(0, 0, 0, 0.2);
      border-top-color: var(--primary);
      margin: 0 auto 3.5em auto;
    }

    .txt-loading {
      font: bold 5em 'Montserrat', sans-serif;
      text-align: center;
      user-select: none;

      .characters {
        color: rgba(0, 0, 0, 0.2);
        position: relative;
        display: inline-block;

        &:before {
          color: var(--primary);
          content: attr(data-text);
          animation: characters 4s infinite;
          left: 0;
          top: 0;
          opacity: 0;
          position: absolute;
          transform: rotateY(-90deg);
        }

        &:nth-child(2):before {
          animation-delay: 0.2s;
        }

        &:nth-child(3):before {
          animation-delay: 0.4s;
        }

        &:nth-child(4):before {
          animation-delay: 0.6s;
        }

        &:nth-child(5):before {
          animation-delay: 0.8s;
        }

        &:nth-child(6):before {
          animation-delay: 1s;
        }
      }
    }
  }

  .loader-section {
    background-color: var(--white);
    height: 100%;
    position: fixed;
    top: 0;
    width: calc(50% + 1px);

    &.section-left {
      left: 0;
    }

    &.section-right {
      right: 0;
    }
  }
}


.loaded {
  .animation-preloader {
    opacity: 0;
    transition: 0.3s ease-out;
  }

  .loader-section {
    &.section-left {
      transform: translateX(-101%);
      transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
    }

    &.section-right {
      transform: translateX(101%);
      transition: 0.7s 0.3s all cubic-bezier(0.1, 0.1, 0.1, 1.000);
    }

  }
}