/*----------------------------------------*/
/*  01. Button CSS START
/*----------------------------------------*/

.b-radius {
  border-radius: 12px;
}

.wc-btn {
  display: inline-block;

  &-default {
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    color: var(--white);
    text-transform: capitalize;
    background: transparent;
    border-radius: 100px;
    padding: 17px 35px;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    border: 1px solid var(--black-6);
    overflow: hidden;
    transition: all 0.3s;

    &:hover {
      color: var(--white);
    }

    @include light {
      color: var(--black-2);

      &:hover {
        color: var(--black-2);
      }
    }

    i {
      @include rtl {
        transform: rotateY(180deg);
      }
    }
  }

  &-primary {
    padding: 22px 40px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    color: var(--black-2);
    text-transform: capitalize;
    background-color: var(--primary);
    transition: all 0.3s;
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    gap: 10px;

    &:hover {
      color: var(--black-2);
      background-color: var(--white);

      @include light {
        color: var(--white);
        background-color: var(--black-2);
      }
    }

    i {
      @include rtl {
        transform: rotateY(180deg);
      }
    }
  }

  &-underline {
    gap: 10px;
    align-items: center;
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: var(--white);
    padding-bottom: 5px;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background-color: var(--white);
      transition: all 0.3s;
    }

    @include light {
      color: var(--black-2);

      &::after {
        background-color: var(--black-2);
      }
    }

    &:hover {
      color: var(--primary);

      &::after {
        width: 0%;
      }
    }
  }

  &-border {
    gap: 10px;
    display: inline-flex;
    align-items: center;
    color: var(--white);
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    padding: 16px 30px;
    border: 1px solid var(--white);
    overflow: hidden;
    transition: all 0.3s;
    z-index: 1;
    position: relative;

    &:hover {
      color: var(--white);
    }

    @include light {
      color: var(--black-2);
      border-color: var(--black-2);

      &:hover {
        color: var(--black-2);
      }
    }
  }
}

// Button wrapper
.btn-wrapper {
  display: inline-block;
  position: relative;
  z-index: 1;
}

// Border Bottom
.border-btm {
  z-index: 2;
  position: relative;
  border-bottom: 1px solid var(--blue-4);

  @include light {
    border-color: var(--pink-12);
  }
}

.btn {
  &-hover {
    &-divide {
      overflow: hidden;
      position: relative;
      transition: all 1s;
      z-index: 1;

      &::after {
        position: absolute;
        content: "";
        width: 150%;
        height: 0%;
        left: 50%;
        top: 50%;
        background-color: var(--primary);
        transform: translateX(-50%) translateY(-50%) rotate(90deg);
        transition: all 0.75s;
        opacity: 0.5;
        z-index: -1;
      }

      &:hover {
        border-color: var(--primary);
        background-color: transparent;

        &::after {
          height: 400%;
          opacity: 1;
        }
      }
    }
  }
  &-rollover {
    &-top {
      position: relative;
      transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;

      &:before {
        position: absolute;
        left: 0px;
        bottom: 0px;
        height: 0px;
        width: 100%;
        z-index: -1;
        content: "";
        background-color: var(--primary);
        transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
      }

      &:hover {
        border-color: var(--primary);
        background-color: transparent;

        &::before {
          top: 0%;
          bottom: auto;
          height: 100%;
        }
      }
    }
  }
}
