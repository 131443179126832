.project-card {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 150px;

  &:first-child {
    margin-top: 0;
  }

  @media #{$lg} {
    margin-top: 100px;
  }

  @media #{$md} {
    margin-top: 50px;
  }
  
  &:hover {
    .title {
      color: var(--primary);
    }
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    color: var(--white);
    padding-top: 20px;
    padding-bottom: 8px;
    transition: all 0.3s;

    @include light {
      color: var(--black-2);
    }
  }

  .info {
    display: inline-block;
    text-align: start;

    img {
      max-width: 100%;
    }

    p {
      font-size: 14px;
    }

    @media #{$sm} {
      display: block;
    }

    .thumb {
      overflow: hidden;
    }
  }
}

.home-project-section {
  .area {
    .shape {
      position: absolute;
      bottom: -12px;
      inset-inline-start: calc(33.33% - 11px);
      z-index: 1;

      &-2 {
        position: absolute;
        bottom: -12px;
        inset-inline-start: calc(66.66% - 11px);
        z-index: 1;
      }
    }

    .line {
      border-top: 1px solid var(--black-5);
      border-bottom: 1px solid var(--black-5);

      @include light {
        border-color: var(--white-4);
      }
    }

    .sec-title-5 {
      position: relative;
      z-index: 2;
      padding-bottom: 60px;

      @media #{$sm} {
        padding-bottom: 50px;
      }
    }

    .title {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 120px;
      align-items: flex-end;

      @media #{$sm} {
        grid-template-columns: 1fr;
        grid-gap: 50px;
      }

      .services__box {
        margin-bottom: 35px;

        @media #{$sm} {
          margin-bottom: 0;
        }

        .years-since {
          font-size: 14px;
          color: #999999;
          text-transform: uppercase;
          font-weight: 400;
          position: relative;
          display: inline-block;
          font-family: $font_glorifydemo;
          margin-bottom: 25px;

          @include light {
            color: #555555;
          }

          &:after {
            position: absolute;
            content: "";
            width: 30px;
            height: 1px;
            background: #999999;
            inset-inline-start: calc(100% + 5px);
            top: 50%;
            transform: translateY(-50%);

            @include light {
              background: #555555;
            }
          }
        }

        .list {
          li {
            font-size: 24px;
            color: var(--white);
            text-transform: uppercase;
            font-family: $font_glorifydemo;
            line-height: 1;

            @include light {
              color: var(--black-2);
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }

  .items {
    columns: 2;
    column-gap: 90px;

    @media #{$md} {
      column-gap: 60px;
    }

    @media #{$sm} {
      columns: 1;
      column-gap: 30px;
    }
  }
}

.project-section {
  .sec-sub-title-18 {
    @media #{$md} {
      margin-bottom: 30px;
    }

    @media #{$sm} {
      margin-bottom: 20px;
    }
  }

  .items {
    columns: 2;
    column-gap: 90px;

    @media #{$md} {
      column-gap: 60px;
    }

    @media #{$sm} {
      columns: 1;
      column-gap: 30px;
    }
  }

  .show-more {
    .wc-btn-default {
      font-size: 18px;
      font-weight: 600;
      padding: 30px 70px;
      background-color: var(--white);
      color: var(--black-2);
      text-transform: uppercase;
      border-color: transparent;

      @include light {
        background-color: var(--black-2);
        color: var(--white);
      }

      @media #{$lg} {
        padding: 20px 40px;
        font-weight: 500;
      }

      &:hover {
        background: transparent;

        @include light {
          color: var(--black-2);
        }
      }
    }
  }
}

.project-details {
  .sec-title-18 {
    @media #{$sm} {
      font-size: 40px;
    }
  }
  .title {
    font-size: 60px;
    font-weight: 400;
    text-transform: uppercase;

    @media #{$md} {
      font-size: 37px;
      margin-bottom: 15px;
    }

    @media #{$sm} {
      font-size: 30px;
      margin-bottom: 15px;
    }
  }

  p {
    font-size: 18px;

    @media #{$sm} {
      font-size: 16px;
    }
  }

  .row {
    @media #{$sm} {
      padding-bottom: 10px;
    }
  }

  .cf_image {
    text-align: center;
    img {
      object-fit: cover;
      margin: auto;
    }
  }
}
