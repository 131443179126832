.experience-section {
  .items-wrapper {
    display: flex;
    justify-content: center;
  }

  .item {
    text-align: center;
    max-width: 520px;

    .wc-counter {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .number {
      font-size: 500px;
      font-weight: 400;
      color: var(--white);
      line-height: 0.93;

      margin-bottom: -60px;

      @include light {
        color: var(--black-2);
      }

      @media #{$xl} {
        font-size: 400px;
      }

      @media #{$lg} {
        font-size: 300px;
        margin-bottom: -20px;
      }

      @media #{$md} {
        font-size: 200px;
      }

      @media #{$sm} {
        font-size: 150px;
        margin-bottom: 0;
      }
    }

    .plus {
      font-size: 90px;

      @media #{$sm} {
        font-size: 50px;
      }
    }

    .text {
      p {
        font-size: 40px;
        font-weight: 400;
        line-height: 1;
        text-transform: uppercase;
        color: var(--white);
        font-family: $font_glorifydemo;

        @include light {
          color: var(--black-2);
        }

        @media #{$sm} {
          font-size: 26px;
        }

        span {
          color: #555555;
          text-decoration: underline;

          @include light {
            color: #999999;
          }
        }
      }

      .hover {
        &_text {
          position: relative;

          &:hover {
            .hover_img {
              opacity: 1;
            }
          }
        }

        &_img {
          width: 300px;
          height: 400px;
          position: absolute;
          top: -50%;
          inset-inline-start: -50%;
          background-size: cover;
          background-repeat: no-repeat;
          opacity: 0;
          transition: opacity 0.3s,
            transform 0.7s cubic-bezier(0.23, 1, 0.32, 1);
          overflow: hidden;
          pointer-events: none;
          z-index: 1;
        }
      }
    }
  }
}
