.home-about {
  .area {
    .shape {
      position: absolute;
      bottom: -12px;
      inset-inline-start: calc(33.33% - 11px);
      z-index: 1;

      &-2 {
        position: absolute;
        bottom: -12px;
        inset-inline-start: calc(66.66% - 11px);
        z-index: 1;
      }
    }

    .sec-text {
      padding-bottom: 65px;

      @media #{$sm} {
        padding-bottom: 40px;
      }

      p {
        column-count: 2;
        gap: 120px;
        text-align: justify;

        @media #{$sm} {
          column-count: 1;
        }
      }
    }
  }

  .text {
    padding-inline-start: 100px;

    @media #{$xl} {
      padding-inline-start: 0;
    }

    @media #{$md} {
      padding-top: 50px;
    }

    @media #{$sm} {
      padding-top: 30px;
    }
  }

  .btn a {
    border-radius: 15px;
    background-color: var(--white);

    @include light {
      background-color: var(--black-2);
      color: var(--white);
    }

    svg {
      @include rtl {
        transform: rotateY(180deg);
      }
    }
  }
}

.home-about-2 {
  .area {
    position: relative;
    z-index: 1;
  }

  .area-bg {
    position: absolute;
    width: calc(100% - 200px);
    height: calc(100% - 200px);
    top: 100px;
    inset-inline-start: 100px;
    z-index: -1;

    @media #{$xxl} {
      width: calc(100% - 140px);
      height: calc(100% - 140px);
      top: 70px;
      inset-inline-start: 70px;
    }

    @media #{$sm} {
      width: calc(100% - 0px);
      height: calc(100% - 0px);
      top: 0;
      inset-inline-start: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .area-inner {
    display: grid;
    align-items: center;
    grid-template-columns: 48% 1fr;
    grid-gap: 110px;
    padding: 0 110px;

    @media #{$lg} {
      grid-gap: 60px;
      padding: 0 60px;
    }

    @media #{$md} {
      grid-template-columns: 1fr;
    }

    @media #{$sm} {
      padding: 0 0;
    }
  }

  .sec-title-wrapper {
    text-align: center;
    margin-bottom: 50px;

    .sec-sub-title-18 {
      margin-bottom: 50px;

      @media #{$sm} {
        margin-bottom: 20px;
      }
    }
  }

  .title {
    font-size: 50px;
    color: var(--white);
    margin-bottom: 30px;
    text-transform: uppercase;

    @include light {
      color: var(--black-2);
    }
  }

  .text {
    p {
      font-size: 18px;

      @include light {
        color: #555555;
      }
    }
  }

  .sticker {
    margin-top: 95px;

    @media #{$lg} {
      margin-top: 30px;
    }

    @media #{$sm} {
      margin-top: 50px;
    }
  }

  .company-name {
    font-size: 20px;
    margin-top: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--white);
    font-family: Glorify DEMO;

    @include light {
      color: var(--black-2);
    }
  }

  .min-height {
    min-height: 400px;

    @media #{$xl} {
      min-height: 250px;
    }

    @media #{$lg} {
      min-height: 280px;
    }

    @media #{$md} {
      min-height: 320px;
    }
    @media #{$sm} {
      min-height: 200px;
    }
  }
}
