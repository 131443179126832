.light {
  margin-bottom: -1px;

  p {
    color: var(--gray);

    span {
      color: var(--black-2);
    }
  }

  .icon-circle {
    background-color: var(--white-3);
  }

} 