.text-slide-section {
  .root-area {
    text-align: center;
    position: relative;
    padding-top: 60px;
    padding-bottom: 160px;
    z-index: 1;

    @media #{$xl} {
      padding-bottom: 140px;
    }

    @media #{$lg} {
      padding-bottom: 110px;
    }

    @media #{$sm} {
      padding-bottom: 130px;
    }

    .line-col-4 div {
      top: -800px;
      height: 1000px;
    }
  }

  .text-slider {
    .text-slide-content {
      margin-inline-end: 8vw;
    }
    .title {
      font-weight: 400;
      font-size: 224px;
      line-height: 1.3;
      color: var(--white);

      @include light {
        color: var(--black-2);
      }

      @media #{$xxl} {
        font-size: 160px;
      }

      @media #{$lg} {
        font-size: 120px;
      }

      @media #{$md} {
        font-size: 90px;
      }

      @media #{$sm} {
        font-size: 60px;
      }

      span {
        font-family: $font_newyork;
      }
    }
  }

  .line-col-4 div {
    top: -800px;
    height: 1000px;
  }
}
