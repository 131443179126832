.best-award-section {
  .sec-title-wrapper {
    margin-bottom: 35px;
  }

  .area-text-box {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    gap: 50px;

    @media #{$md} {
      grid-template-columns: auto;
    }

    .sec-title-18 {
      font-size: 100px;

      @media #{$xl} {
        font-size: 90px;
      }

      @media #{$lg} {
        font-size: 70px;
      }

      @media #{$md} {
        font-size: 60px;
      }

      @media #{$sm} {
        font-size: 40px;
      }
    }
  }

  .text {
    max-width: 410px;
    margin-top: 45px;

    p {
      font-size: 18px;

      @include light {
        color: #555555;
      }
    }
  }

  .list {
    display: flex;
    gap: 10px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: var(--white);
      text-transform: uppercase;
      border: 1.2px solid var(--white);
      border-radius: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;

      @include light {
        color: var(--black-2);
        border-color: var(--black-2);
      }
    }
  }
}

.award-section {
  .area {
    .sec-title-5 {
      padding-bottom: 60px;

      @media #{$sm} {
        padding-bottom: 40px;
      }
    }

    .sec-text {
      max-width: 330px;

      @media #{$sm} {
        padding-bottom: 30px;
      }

      p {
        font-size: 18px;
      }
    }

    .shape {
      position: absolute;
      bottom: -12px;
      inset-inline-start: calc(33.33% - 11px);
      z-index: 1;

      &-2 {
        position: absolute;
        bottom: -12px;
        inset-inline-start: calc(66.66% - 11px);
        z-index: 1;
      }
    }
  }

  .item {
    display: grid;
    grid-template-columns: 1fr 3.2fr 1fr 1fr;
    align-items: center;
    padding: 23px 0;
    border-top: 1px solid var(--black-5);

    @include light {
      border-color: var(--white-4);
    }

    @media #{$sm} {
      grid-template-columns: 0.7fr 3.5fr 1fr 1fr;
      gap: 20px;
    }

    &:last-child {
      border-bottom: 1px solid var(--black-5);

      @include light {
        border-color: var(--white-4);
      }
    }

    img {
      @media #{$md} {
        width: 55% !important;
      }

      @media #{$sm} {
        width: 90% !important;
      }
    }
  }

  .number {
    font-size: 18px;
  }

  .date {
    font-size: 18px;
    text-align: end;

    @media #{$sm} {
      text-align: start;
    }
  }

  .title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
    color: var(--white);

    @include light {
      color: var(--black-2);
    }

    @media #{$sm} {
      br {
        display: block;
      }
    }
  }
}
