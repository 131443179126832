/*----------------------------------------*/
/*  01. MENU CSS START
/*----------------------------------------*/

.main-menu {
  >ul {
    display: flex;

    >li {
      >a {
        color: var(--white);

        @include light {
          color: var(--black-2);
        }
      }
      >span {
        @include light {
          color: var(--black-2);
        }
        svg {
          transition: 0.3s;
        }
      }
      &:hover {
        >a {
          color: var(--primary) !important;
        }
        >span {
          color: var(--primary) !important;

          svg {
            transform: rotateX(180deg);
            transition: 0.3s;
          }
        }

        >ul {
          opacity: 1;
          pointer-events: all;
          left: 0;

          &.dp-menu li {
            &:hover {
              >ul {
                opacity: 1;
                pointer-events: all;
                left: 100%;
              }
            }
          }
        }
      }
    }
  }

  li {
    position: relative;

    a, span {
      display: block;
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: var(--white);
      padding: 40px 25px;
      text-transform: capitalize;
    }
  }



  .dp-menu {
    background: var(--black-2);
    padding: 18px 0px;
    width: 300px;
    position: absolute;
    inset-inline-start: 10px;
    top: calc(100% - 1px);
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    transition: all 0.5s;

    ul {
      background: var(--black-2);
      padding: 18px 0px;
      width: 300px;
      position: absolute;
      inset-inline-start: calc(100% + 10px);
      top: 0;
      opacity: 0;
      z-index: 10;
      transition: all 0.5s;
    }

    li {
      position: relative;
      padding: 0 20px;

      &:hover {
        >a {
          color: var(--white);
          background-color: #2C2C2F;
        }

        >ul {
          opacity: 1;
          transform: none !important;
          pointer-events: all;
        }
      }

      a {
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        padding: 9px 15px;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: var(--black-2);
        border-radius: 8px;


      }
    }
  }

  .has-mega-menu {
    position: static;


  }

  li:has(ul) {
    >a {
      &:after {
        content: "\f107";
        font-family: var(--font_awesome);
        margin-inline-start: 5px;
        font-weight: 600;
      }
    }
  }

  .mega-menu {
    background-color: var(--black-2);
    padding: 30px 50px;
    width: 100%;
    position: absolute;
    left: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 50px;
    justify-content: center;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: 10;
    transition: all 0.5s;


    @media #{$xl} {
      column-gap: 30px;
    }

    li:has(ul) {
      >a {
        &:after {
          content: "";
        }
      }
    }

    li {
      a {
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        height: 40px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        gap: 8px;
        background: var(--black-2);
        border-radius: 8px;
        overflow: hidden;

        &:hover {
          color: var(--white);
          background: #2C2C2F;
        }
      }
    }

    .title {
      font-weight: 600;
      color: var(--white);
      text-transform: uppercase;
      border-bottom: 1px solid #333337;
      padding-bottom: 20px;
      margin-bottom: 20px;
      pointer-events: none;
      border-radius: 0;
    }
  }

  .mega-style-2 {
    padding: 0 15%;
    gap: 0;
    grid-template-columns: repeat(2, 1fr);

    .title {
      height: 70px;
      padding-bottom: 0;
      margin-bottom: 0;
      position: relative;
      overflow: visible;
      padding-inline-start: 30px;

      &:after {
        position: absolute;
        content: "";
        width: 5000px;
        height: 1px;
        background-color: #333337;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    >li {
      &:not(:first-child) {
        border-left: 1px solid #333337;
      }
    }

    ul {
      column-count: 2;
      position: relative;
      padding: 20px 0;

      &:after {
        position: absolute;
        content: "";
        width: 1px;
        height: 700px;
        background-color: #333337;
        top: 0;
        left: 50%;
        z-index: 1;
      }

      li {

        a {
          padding-inline-start: 30px;
        }
      }
    }
  }

  .mega-style-3 {
    padding: 0 0 0 20px;
    gap: 0;
    grid-template-columns: repeat(3, 1fr);

    .title {
      height: 70px;
      padding-bottom: 0;
      margin-bottom: 0;
      position: relative;
      overflow: visible;
      padding-inline-start: 30px;

      &:after {
        position: absolute;
        content: "";
        width: 5000px;
        height: 1px;
        background-color: #333337;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    >li {
      &:not(:first-child) {
        border-left: 1px solid #333337;
      }

      &:last-child {
        border: none;
        width: 36vw;

        @media #{$xl} {
          width: 32vw;
        }
      }
    }

    ul {
      column-count: 2;
      position: relative;
      padding: 20px 0;
      column-gap: 0;

      &:after {
        position: absolute;
        content: "";
        width: 1px;
        height: 700px;
        background-color: #333337;
        top: 0;
        left: 50%;
        z-index: 1;
      }

      li {
        margin: 0 10px;

        a {
          padding-inline-start: 20px;
        }
      }
    }
  }

  .mega-grid-6 {
    grid-template-columns: repeat(6, 1fr);
  }

  .mega-grid-2 {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 60px;
  }

  .list-3-column {
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 50px;

      @media #{$xl} {
        column-gap: 30px;
      }
    }
  }

  .span-first-item {
    ul {
      li:first-child {
        grid-column: 1 / -1;
        column-span: all;
      }
    }
  }


  .new {
    font-size: 10px;
    font-weight: 600;
    background: #FFA38E;
    color: var(--black-2);
    padding: 3px 7px;
    line-height: 1;
    display: flex;
    align-items: center;
    border-radius: 2px;
  }

  &-2 {
    @media #{$lg} {
      display: none;
    }

    li {
      display: inline-block;
      padding: 0 10px;

      a {
        display: block;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.5;
        color: var(--white);
        padding: 10px;
        text-transform: capitalize;

        &:hover {
          color: var(--primary);
        }

        @media #{$xl} {
          padding: 5px 0;
        }
      }
    }
  }

  &-3 {
    li {
      display: inline-block;
      margin-inline-end: 45px;

      @media #{$lg} {
        margin-inline-end: 25px;
      }

      &:last-child {
        margin-inline-end: 0;
      }

      a {
        color: var(--white);
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  &-4 {
    li {
      display: inline-block;
      margin-inline-end: 50px;

      a {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: var(--white);
        text-transform: uppercase;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }


}

.menu-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin-top: 5px;
  display: inline-block;

  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    top: 0;
    left: 0;
    background: var(--white);
    transition: all 0.3s;
  }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: var(--white);
    transition: all 0.3s;
  }

  span {
    position: absolute;
    content: "";
    width: 18px;
    height: 1px;
    top: 50%;
    right: 0;
    transition: all 0.3s;
    background-color: var(--white);
  }

  &:hover {

    &::after,
    &::before,
    span {
      background-color: var(--primary);
    }
  }

  &-2 {
    @extend .menu-icon;
    width: 24px;
    height: 18px;

    span {
      width: 15px;
      right: unset;
      left: 0;
    }
  }
}

.offcanvas__menu-wrapper.mean-container {
  .mean-nav > ul {
    padding: 0;
    margin: 0;
    width: 100%;
    list-style-type: none;
    display: block !important;

    > li:last-child > a {
      border-bottom: 1px solid var(--black-4);
    }
  }

  .mean-nav {
    background: none;
    margin-top: 0;

    ul li {
      a {
        width: 100%;
        padding: 20px 0;
        font-weight: 400;
        font-size: 24px;
        line-height: 1;
        color: var(--white);
        text-transform: capitalize;
        border-top: 1px solid var(--black-4);
        display: flex;
        justify-content: space-between;
        align-items: center;
        outline: none;
        transform: translateY(var(--y)) translateZ(0);
        transition: transform 0.4s ease, box-shadow 0.4s ease;

        @media #{$sm} {
          font-size: 20px;
          padding-inline-start: 15px;
        }

        &.mean-expand {
          width: 40px;
          height: 40px;
          justify-content: center;
          font-weight: 300;
          border: none !important;

          @media #{$sm} {
            height: 36px;
          }

          &:hover {
            background: var(--secondary);
            opacity: 1;
          }
        }
      }

      li {
        &:first-child {
          border-top: 1px solid var(--black-4);
        }

        a {
          font-size: 20px;
          text-transform: capitalize;
          border-top: none !important;
          padding: 20px 0 20px 30px;

          @media #{$sm} {
            font-size: 18px;
          }

          &.mean-expand {
            height: 46px;

            @media #{$md} {
              height: 25px;
            }

            @media #{$sm} {
              height: 22px;
            }
          }
        }

        li {
          &:last-child {
            border-bottom: 1px solid var(--black-4);
          }

          a {
            padding-inline-start: 180px;

            @media #{$sm} {
              padding-inline-start: 30px;
            }
          }
        }
      }
    }
  }

  .mean-bar {
    padding: 0;
    background: none;
  }

  a.meanmenu-reveal {
    display: none !important;
  }
}

.light {
  .main-menu {

    li {
      a {
        // color: var(--black-2);

        &:hover {
          color: var(--primary);
        }
      }
    }

    &-2 {
      li {
        a {
          color: var(--black-2);

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

    &-3 {
      li {
        a {
          color: var(--black-2);

          &:hover {
            color: var(--primary);
          }
        }
      }
    }

  }
}
